import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SpinnerLabel from "./spinner-label";
import Feedback from "./feedback";
import { SELECTED_MAILCHIMP_LIST_PROP_TYPES } from "../constants/proptypes";

const LoyaltyMergeFieldMappingValidation = ({
  createMailchimpLoyaltyMergeField,
  creatingMailchimpLoyaltyMergeField,
  creatingMailchimpLoyaltyMergeFieldIsSuccess,
  errorCreatingMailchimpLoyaltyMergeField,
  mailchimpList,
  setLoyaltyMergeFieldActionRequired,
}) => {
  const [optInContinueWithoutLoyaltyData, setOptInContinueWithoutLoyaltyData] =
    useState(false);
  const [tooManyMergeFieldsResolved, setTooManyMergeFieldsResolved] =
    useState(false);
  const [actionRequired, setActionRequired] = useState(false);

  useEffect(() => {
    createMailchimpLoyaltyMergeField();
  }, []);

  useEffect(() => {
    setLoyaltyMergeFieldActionRequired(actionRequired);
  }, [actionRequired]);

  useEffect(() => {
    if (errorCreatingMailchimpLoyaltyMergeField === true) {
      setActionRequired(true);
    }
  }, [errorCreatingMailchimpLoyaltyMergeField]);

  useEffect(() => {
    setActionRequired(!optInContinueWithoutLoyaltyData);
  }, [optInContinueWithoutLoyaltyData]);

  useEffect(() => {
    setActionRequired(!tooManyMergeFieldsResolved);
  }, [tooManyMergeFieldsResolved]);

  useEffect(() => {
    if (creatingMailchimpLoyaltyMergeFieldIsSuccess === true) {
      setTooManyMergeFieldsResolved(true);
    }
  }, [creatingMailchimpLoyaltyMergeFieldIsSuccess]);

  const handleClickContinueWithoutLoyaltyData = () => {
    setOptInContinueWithoutLoyaltyData(true);
  };

  const handleClickMergeFieldsRefresh = () => {
    createMailchimpLoyaltyMergeField();
  };

  return creatingMailchimpLoyaltyMergeField ? (
    <SpinnerLabel label="Checking your loyalty program and creating necessary merge fields..." />
  ) : (
    <>
      {errorCreatingMailchimpLoyaltyMergeField && actionRequired && (
        <Feedback
          status="error"
          label="You've reached the merge field limit in Mailchimp"
        >
          <div className="flex flex-row justify-left">
            <div className="wink-feedback-message-text w-70">
              We can&apos;t import your loyalty program data to Mailchimp
              because you&apos;ve reached the maximum number of merge fields for
              your account level. To sync customer loyalty points from Square,{" "}
              {mailchimpList ? (
                <a
                  target="_blank"
                  className="no-underline"
                  rel="noreferrer"
                  href={`https://admin.mailchimp.com/lists/settings/merge-tags/?id=${mailchimpList.webId}`}
                  title="delete an existing merge field in Mailchimp"
                >
                  delete an existing merge field in Mailchimp
                </a>
              ) : (
                "delete an existing merge field in Mailchimp"
              )}{" "}
              and choose &quot;Refresh&quot; or continue without syncing
              customer loyalty points.
            </div>
            <button
              type="button"
              className="link bold no-underline mx-auto"
              onClick={handleClickMergeFieldsRefresh}
            >
              Refresh
            </button>
            <button
              type="button"
              className="link bold no-underline mx-auto"
              onClick={handleClickContinueWithoutLoyaltyData}
            >
              Continue
            </button>
          </div>
        </Feedback>
      )}
      {optInContinueWithoutLoyaltyData && (
        <Feedback
          status="info"
          label="Syncing without customer loyalty points"
          dismiss
        >
          <div className="flex flex-row justify-left">
            <div className="w-70">
              We won&apos;t import your Square loyalty program data because you
              reached the merge field limit in Mailchimp. To sync customer
              loyalty points, choose &quot;Go Back&quot; to update your merge
              fields.
            </div>
            <button
              type="button"
              className="link bold no-underline mx-auto"
              onClick={() => setOptInContinueWithoutLoyaltyData(false)}
              title="change your mind"
            >
              Go Back
            </button>
          </div>
        </Feedback>
      )}
      {tooManyMergeFieldsResolved && (
        <Feedback
          status="success"
          label="Ready to sync Square loyalty program data"
          dismiss
        >
          Your customer loyalty points are ready to sync to Mailchimp.
        </Feedback>
      )}
    </>
  );
};

LoyaltyMergeFieldMappingValidation.propTypes = {
  createMailchimpLoyaltyMergeField: PropTypes.func.isRequired,
  creatingMailchimpLoyaltyMergeField: PropTypes.bool.isRequired,
  creatingMailchimpLoyaltyMergeFieldIsSuccess: PropTypes.bool.isRequired,
  errorCreatingMailchimpLoyaltyMergeField: PropTypes.bool.isRequired,
  mailchimpList: SELECTED_MAILCHIMP_LIST_PROP_TYPES.isRequired,
  setLoyaltyMergeFieldActionRequired: PropTypes.func.isRequired,
};

export default LoyaltyMergeFieldMappingValidation;
