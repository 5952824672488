import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Modal from "./modal";
import SpinnerLabel from "./spinner-label";
import { MAILCHIMP_MERGE_FIELD } from "../constants/proptypes";

const NewMergeFieldModal = ({
  newMailchimpMergeField,
  cancel,
  createMailchimpMergeField,
  creatingMailchimpMergeField,
  setNewMailchimpMergeField,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      fieldLabel: newMailchimpMergeField?.name,
      contentTag: newMailchimpMergeField?.tag,
    },
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const [remoteError, setRemoteError] = useState(null);

  if (!newMailchimpMergeField) return null;

  const fieldHasErrors = (field) => errors[field];

  return (
    <Modal isOpen modalClassName="modal-small" close={() => cancel(false)}>
      <h3>Create a new Merge Field</h3>
      <p>
        Have some data without a home? Add a new merge field to your Mailchimp
        audience. You can modify fields later from your Mailchimp account.
      </p>

      <p
        aria-describedby="field-label-label"
        className="wink-form-description wink-form-description-error"
      >
        {remoteError}
      </p>

      <form
        onSubmit={handleSubmit(async (values) => {
          const result = await createMailchimpMergeField({
            type: "text",
            name: values.fieldLabel,
            tag: values.contentTag,
          });

          if (!result.error) {
            setNewMailchimpMergeField(result.data);
          } else {
            setRemoteError(result.error.data.detail);
          }
        })}
      >
        <fieldset>
          <div className="wink-form-field-container">
            <div className="wink-form-label-container">
              <label
                className="wink-form-label"
                htmlFor="field-label"
                id="field-label-label"
              >
                Field Label
              </label>
              <span
                aria-describedby="field-label-label"
                className="wink-form-label-secondary"
              >
                Required
              </span>
            </div>
            <input
              className={`wink-form-field ${
                fieldHasErrors("fieldLabel") ? "wink-form-field-error" : ""
              }`}
              maxLength="100"
              {...register("fieldLabel", {
                required: true,
              })}
              aria-labelledby="field-label-label"
            />
            <p
              aria-describedby="field-label-label"
              className="wink-form-description wink-form-description-error"
            >
              {errors.fieldLabel?.type === "required" &&
                "Field Label is a required field."}
            </p>
          </div>
          <div className="wink-form-field-container">
            <div className="wink-form-label-container">
              <label
                className="wink-form-label"
                htmlFor="content-tag"
                id="content-tag-label"
              >
                Content Tag
              </label>
              <span
                aria-describedby="content-tag-label"
                className="wink-form-label-secondary"
              >
                Required
              </span>
            </div>
            <input
              className={`wink-form-field ${
                fieldHasErrors("contentTag") ? "wink-form-field-error" : ""
              }`}
              maxLength="10"
              {...register("contentTag", {
                required: true,
                // TODO: this can only be 10 characters long.
                onChange: (e) => {
                  const field = e.target;
                  field.value = field.value
                    .trim()
                    .toUpperCase()
                    .replace(/[^a-zA-Z0-9]+/g, "_")
                    .substr(0, 10);
                },
              })}
              aria-labelledby="content-tag-label"
            />
            <p
              aria-describedby="content-tag-label"
              className="wink-form-description wink-form-description-error"
            >
              {errors.contentTag?.type === "required" &&
                "Content Tag is a required field."}
            </p>
          </div>
        </fieldset>
        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-secondary"
            onClick={() => cancel(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="wink-button wink-button-primary"
            disabled={creatingMailchimpMergeField}
          >
            {creatingMailchimpMergeField ? (
              <SpinnerLabel label="Create Mailchimp Merge Field" />
            ) : (
              "Create Mailchimp Merge Field"
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

NewMergeFieldModal.propTypes = {
  newMailchimpMergeField: MAILCHIMP_MERGE_FIELD.isRequired,
  cancel: PropTypes.func.isRequired,
  createMailchimpMergeField: PropTypes.func.isRequired,
  creatingMailchimpMergeField: PropTypes.bool.isRequired,
  setNewMailchimpMergeField: PropTypes.func.isRequired,
};

export default NewMergeFieldModal;
