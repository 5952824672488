import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import Select, { components } from "react-select";
import creatableSelectStyles from "constants/creatable-select-styles";
import {
  MAILCHIMP_LIST_PROP_TYPES,
  SELECTED_MAILCHIMP_LIST_PROP_TYPES,
} from "../constants/proptypes";
import NotAllowedIcon from "./icons/not-allowed-icon";
import SpinnerLabel from "./spinner-label";
import TickCircleIcon from "./icons/tick-circle-icon";
import CreateMailchimpListContainer from "../containers/create-mailchimp-list-container";

const toSelectObject = (list) => ({
  value: list?.id,
  label: list?.name,
});

const ClearIndicator = (props) => (
  <div data-testid="clear-select">
    <components.ClearIndicator {...props} data-testid="clear-select" />
  </div>
);

const MailchimpList = ({
  lists,
  fetchingLists,
  canCreateList,
  selectedList = null,
  errorLoadingLists,
  onSelectedListChange,
}) => {
  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [newListName, setNewListName] = useState("");

  const [createdNewList, setCreatedNewList] = useState(false);

  const Selector = useMemo(
    () => (canCreateList ? CreatableSelect : Select),
    [canCreateList]
  );

  const selectedListObject = useMemo(
    () =>
      toSelectObject(
        lists.find((list) => list?.id === selectedList?.remoteListId)
      ),
    [lists, selectedList]
  );

  const options = lists.map((list) => ({
    value: list.id,
    label: list.name,
    webId: list.webId,
  }));

  return (
    <>
      {errorLoadingLists && (
        <div className="wink-feedback wink-feedback-error">
          <NotAllowedIcon className="wink-feedback-icon" />
          <div className="wink-feedback-message">
            <h2 className="wink-text-tag">
              We couldn&apos;t load your Mailchimp audiences.
            </h2>
            <p className="wink-text-small">
              There was an unexpected error loading your mailchimp audiences.
            </p>
          </div>
        </div>
      )}

      {createdNewList && (
        <div className="wink-feedback wink-feedback-success wink-feedback-icon-success">
          <TickCircleIcon className="wink-feedback-icon" />
          <div className="wink-feedback-message">
            <h2 className="wink-text-tag">
              Your new audience has been created!
            </h2>
          </div>
        </div>
      )}

      <div className="wink-form-field-container">
        <div className="wink-form-label-container">
          <label
            className="wink-form-label"
            htmlFor="mailchimp-list"
            id="mailchimp-list-label"
          >
            Choose an audience from Mailchimp
          </label>
          <span
            aria-describedby="mailchimp-list-label"
            className="wink-form-label-secondary"
          >
            Required
          </span>
        </div>
        {fetchingLists && <SpinnerLabel label="Loading your audiences..." />}
        {!fetchingLists && (
          <>
            <Selector
              isClearable
              isSearchable={canCreateList}
              styles={creatableSelectStyles()}
              onChange={(selectedList) => {
                if (selectedList === null) {
                  onSelectedListChange(null);
                  // eslint-disable-next-line no-underscore-dangle
                } else if (selectedList?.__isNew__) {
                  setNewListName(selectedList.label);
                  setShowConfigDialog(true);
                } else {
                  onSelectedListChange({
                    remoteListId: selectedList.value,
                    listName: selectedList.label,
                    webId: selectedList.webId,
                  });
                }
              }}
              value={selectedListObject}
              isDisabled={fetchingLists}
              options={options}
              id="mailchimp-list"
              aria-labelledby="mailchimp-list-label"
              components={{ ClearIndicator }}
            />
            {canCreateList && (
              <p
                aria-describedby="mailchimp-list-label"
                className="wink-form-description"
              >
                We recommend using a single audience to organize contacts and
                optimize your marketing in Mailchimp. Learn more about{" "}
                <a
                  href="https://mailchimp.com/resources/one-audience-organize-contacts-to-optimize-marketing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  using multiple audiences.
                </a>
              </p>
            )}
          </>
        )}
      </div>

      {showConfigDialog && (
        <CreateMailchimpListContainer
          newListName={newListName}
          close={() => setShowConfigDialog(false)}
          onCreatedNewList={(newList) => {
            onSelectedListChange(newList);
            setCreatedNewList(true);
          }}
        />
      )}
    </>
  );
};

MailchimpList.propTypes = {
  lists: PropTypes.arrayOf(MAILCHIMP_LIST_PROP_TYPES).isRequired,
  fetchingLists: PropTypes.bool.isRequired,
  canCreateList: PropTypes.bool.isRequired,
  selectedList: SELECTED_MAILCHIMP_LIST_PROP_TYPES,
  errorLoadingLists: PropTypes.bool.isRequired,
  onSelectedListChange: PropTypes.func.isRequired,
};

export default MailchimpList;
