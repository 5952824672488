import React from "react";
import PropTypes from "prop-types";

const Spinner = ({ size = "1.6em", testid = null }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 100 100"
    aria-label="Loading..."
    role="status"
    data-testid={testid}
  >
    <g transform="rotate(0 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.9s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(36 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.8s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(72 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.7s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(108 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.6s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(144 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(180 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.4s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(216 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.3s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(252 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.2s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(288 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.1s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(324 50 50)">
      <rect x="44.5" y="6" rx="6" ry="6" width="12" height="12">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
  </svg>
);

Spinner.propTypes = {
  size: PropTypes.string,
  testid: PropTypes.string,
};

export default Spinner;
