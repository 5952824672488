import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MergeFieldMappingsSetup from "../components/merge-field-mappings-setup";
import {
  useCreateMailchimpMergeFieldMutation,
  useGetMailchimpMergeFieldsQuery,
  useGetMergeFieldMappingsQuery,
  useGetSquareCustomAttributeDefinitionsQuery,
  useLoadSessionQuery,
  useUpdateMergeFieldMappingsMutation,
} from "../features/mc-square-api/mc-square-api-slice";
import { simpleSelectorFor } from "../selectors/mc-square-selectors";
import { UNKNOWN_USER } from "../constants/proptypes";

const MergeFieldMappingsSetupContainer = ({ editMode = false }) => {
  const squareMergeFields = useSelector(
    (state) => state.mcSquare.squareMergeFields
  );

  const loyaltyMergeFieldActionRequired = useSelector(
    simpleSelectorFor("loyaltyMergeFieldActionRequired")
  );

  const {
    data: { enabledSetupSteps } = UNKNOWN_USER,
    isLoading: isLoadingSession,
  } = useLoadSessionQuery();

  const [
    triggerCreateMailchimpMergeField,
    { isLoading: creatingMailchimpMergeField },
  ] = useCreateMailchimpMergeFieldMutation();

  const [
    triggerUpdateMergeFieldMappingsMutation,
    {
      isLoading: updatingMergeFieldMappings,
      isSuccess: mergeFieldMappingsUpdateSucceeded,
    },
  ] = useUpdateMergeFieldMappingsMutation();

  const { data: mailchimpMergeFields = [] } = useGetMailchimpMergeFieldsQuery();

  const {
    data: mergeFieldMappings = [],
    isLoading: loadingMergeFieldMappings,
  } = useGetMergeFieldMappingsQuery();

  const {
    data: squareCustomAttributeDefinitions = {},
    isLoading: loadingSquareCustomAttributeDefinitions,
  } = useGetSquareCustomAttributeDefinitionsQuery();

  if (isLoadingSession) {
    return null;
  }

  return (
    <MergeFieldMappingsSetup
      loadingMergeFieldMappings={loadingMergeFieldMappings}
      loadingSquareCustomAttributeDefinitions={
        loadingSquareCustomAttributeDefinitions
      }
      mergeFieldMappings={mergeFieldMappings}
      squareCustomAttributeDefinitions={squareCustomAttributeDefinitions}
      squareMergeFields={squareMergeFields}
      mailchimpMergeFields={mailchimpMergeFields}
      createMailchimpMergeField={triggerCreateMailchimpMergeField}
      creatingMailchimpMergeField={creatingMailchimpMergeField}
      updateMergeFieldMappings={triggerUpdateMergeFieldMappingsMutation}
      updatingMergeFieldMappings={updatingMergeFieldMappings}
      mergeFieldMappingsUpdateSucceeded={mergeFieldMappingsUpdateSucceeded}
      syncEnabled={enabledSetupSteps.includes("sync")}
      editMode={editMode}
      loyaltyMergeFieldActionRequired={loyaltyMergeFieldActionRequired || false}
    />
  );
};

MergeFieldMappingsSetupContainer.propTypes = {
  editMode: PropTypes.bool,
};

export default MergeFieldMappingsSetupContainer;
