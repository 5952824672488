/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import MergeFields from "./merge-fields";
import {
  MAILCHIMP_MERGE_FIELD,
  MERGE_FIELD_MAPPING,
  SQUARE_MERGE_FIELD,
} from "../constants/proptypes";
import Spinner from "./spinner";
import SpinnerLabel from "./spinner-label";
import LoyaltyMergeFieldMappingValidationContainer from "../containers/loyalty-merge-field-mapping-validation-container";
import MergeFieldEditFeedback from "./merge-field-edit-feedback";

const trackClick = (label) =>
  ReactGA.event({
    category: "field mapping",
    action: "click",
    label,
  });

const MergeFieldMappingsSetup = ({
  loadingMergeFieldMappings,
  loadingSquareCustomAttributeDefinitions,
  mergeFieldMappings: initialMergeFieldMappings,
  squareMergeFields: initialSquareMergeFields,
  squareCustomAttributeDefinitions: initialSquareCustomAttributeDefinitions,
  mailchimpMergeFields,
  createMailchimpMergeField,
  creatingMailchimpMergeField,
  updateMergeFieldMappings,
  updatingMergeFieldMappings,
  mergeFieldMappingsUpdateSucceeded,
  syncEnabled,
  editMode = false,
  loyaltyMergeFieldActionRequired,
}) => {
  const [mergeFieldMappings, setMergeFieldMappings] = useState([]);
  const [showMergeFieldSuccessFeedback, setShowMergeFieldSuccessFeedback] =
    useState(false);
  const [showMergeFieldErrorsFeedback, setShowMergeFieldErrorsFeedback] =
    useState(false);
  const FORCE_CUSTOMER_RESYNC = true;

  const buttonLabel = editMode ? "Save Changes" : "Continue to sync";
  const buttonLoadingLabel = editMode
    ? "Saving your changes..."
    : "Continue to sync";
  const [squareMergeFields, setSquareMergeFields] = useState(
    initialSquareMergeFields
  );

  useEffect(() => {
    if (mergeFieldMappings.length) return;

    setMergeFieldMappings(initialMergeFieldMappings);
  }, [initialMergeFieldMappings]);

  useEffect(() => {
    if (mergeFieldMappingsUpdateSucceeded === true)
      setShowMergeFieldSuccessFeedback(true);
  }, [mergeFieldMappingsUpdateSucceeded]);
  useEffect(() => {
    if (
      loadingSquareCustomAttributeDefinitions ||
      initialSquareCustomAttributeDefinitions.length
    )
      return;

    setSquareMergeFields({
      ...squareMergeFields,
      ...initialSquareCustomAttributeDefinitions,
    });
  }, [initialSquareCustomAttributeDefinitions]);

  const navigate = useNavigate();

  const validateSquareField = (mergeFieldMapping) => {
    // is the field provided? no? error!
    if (!mergeFieldMapping.squareField) return "Please select a field to map.";
    // is a field an address, but the corresponding field isn't? error!
    const squareFieldType =
      squareMergeFields[mergeFieldMapping.squareField]?.type;
    const mailchimpFieldType = mailchimpMergeFields.find(
      (mailchimpMergeField) =>
        mailchimpMergeField.tag === mergeFieldMapping.mailchimpField
    )?.type;
    if (
      squareFieldType === "address" &&
      squareFieldType !== mailchimpFieldType
    ) {
      return "Address fields must be mapped to address fields.";
    }

    return null;
  };

  const validateMailchimpField = (mergeFieldMapping, index) => {
    // is the field provided? no? error!
    if (!mergeFieldMapping.mailchimpField)
      return "Please select a field to map.";
    // is a field an address, but the corresponding field isn't? error!
    const squareFieldType =
      squareMergeFields[mergeFieldMapping.squareField]?.type;
    const mailchimpFieldType = mailchimpMergeFields.find(
      (mailchimpMergeField) =>
        mailchimpMergeField.tag === mergeFieldMapping.mailchimpField
    )?.type;
    if (
      mailchimpFieldType === "address" &&
      squareFieldType !== mailchimpFieldType
    ) {
      return "Address fields must be mapped to address fields.";
    }
    // is this the second time we've seen a particular field for mailchimp? error!
    const alreadyMapped = !!mergeFieldMappings
      .slice(0, index)
      .find(
        (otherMergeFieldMapping) =>
          otherMergeFieldMapping.mailchimpField ===
          mergeFieldMapping.mailchimpField
      );
    if (alreadyMapped) return "This Mailchimp merge field is already mapped.";

    return null;
  };

  const validateMergeFields = () => {
    const mergeFieldMappingsWithErrors = mergeFieldMappings.map(
      (mergeFieldMapping, index) => ({
        ...mergeFieldMapping,
        squareFieldErrors: validateSquareField(mergeFieldMapping),
        mailchimpFieldErrors: validateMailchimpField(mergeFieldMapping, index),
      })
    );

    setMergeFieldMappings(mergeFieldMappingsWithErrors);

    return (
      mergeFieldMappingsWithErrors.filter(
        ({ squareFieldErrors, mailchimpFieldErrors }) =>
          squareFieldErrors || mailchimpFieldErrors
      ).length === 0
    );
  };

  const setupFormButtonClick = async () => {
    if (validateMergeFields()) {
      await updateMergeFieldMappings({ mergeFieldMappings });
      if (syncEnabled) navigate("/sync");
    }
    trackClick("next: sync");
  };

  const editFormButtonClick = async () => {
    setShowMergeFieldSuccessFeedback(false);
    setShowMergeFieldErrorsFeedback(false);

    if (validateMergeFields()) {
      await updateMergeFieldMappings({
        mergeFieldMappings,
        resyncCustomers: FORCE_CUSTOMER_RESYNC,
      });
    } else {
      setShowMergeFieldErrorsFeedback(true);
    }
    trackClick("edit");
  };

  const handleFormButtonClick = () => {
    if (editMode) {
      return editFormButtonClick();
    }

    return setupFormButtonClick();
  };

  return (
    <>
      {editMode ? (
        <h2>Map fields from Square</h2>
      ) : (
        <h1>Map fields from Square</h1>
      )}

      <p>
        Information about your Square contacts is stored in Mailchimp audience
        fields. Map the fields from Square to Mailchimp and remove any you don't
        need. (We'll automatically match some fields, such as the Email Address
        field.)
      </p>

      <p>
        <a
          href="https://mailchimp.com/en-ca/help/manage-audience-signup-form-fields/#Audience_field_types"
          target="_blank"
          rel="noreferrer"
        >
          See examples of audience field types.
        </a>
      </p>

      {editMode ? (
        <MergeFieldEditFeedback
          showErrors={showMergeFieldErrorsFeedback}
          showSuccess={showMergeFieldSuccessFeedback}
        />
      ) : (
        <LoyaltyMergeFieldMappingValidationContainer />
      )}

      {loadingMergeFieldMappings || loadingSquareCustomAttributeDefinitions ? (
        <Spinner testid="loading-merge-fields-spinner" />
      ) : (
        <MergeFields
          mergeFieldMappings={mergeFieldMappings}
          setMergeFieldMappings={setMergeFieldMappings}
          squareMergeFields={squareMergeFields}
          mailchimpMergeFields={mailchimpMergeFields}
          createMailchimpMergeField={createMailchimpMergeField}
          creatingMailchimpMergeField={creatingMailchimpMergeField}
        />
      )}

      <form>
        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-primary"
            disabled={
              loyaltyMergeFieldActionRequired || updatingMergeFieldMappings
            }
            onClick={handleFormButtonClick}
          >
            {updatingMergeFieldMappings ? (
              <SpinnerLabel label={buttonLoadingLabel} />
            ) : (
              buttonLabel
            )}
          </button>
        </div>
      </form>
    </>
  );
};

MergeFieldMappingsSetup.propTypes = {
  loadingMergeFieldMappings: PropTypes.bool.isRequired,
  loadingSquareCustomAttributeDefinitions: PropTypes.bool.isRequired,
  mergeFieldMappings: PropTypes.arrayOf(MERGE_FIELD_MAPPING).isRequired,
  squareMergeFields: SQUARE_MERGE_FIELD.isRequired,
  squareCustomAttributeDefinitions: SQUARE_MERGE_FIELD.isRequired,
  mailchimpMergeFields: PropTypes.arrayOf(MAILCHIMP_MERGE_FIELD).isRequired,
  createMailchimpMergeField: PropTypes.func.isRequired,
  creatingMailchimpMergeField: PropTypes.bool.isRequired,
  updateMergeFieldMappings: PropTypes.func.isRequired,
  updatingMergeFieldMappings: PropTypes.bool.isRequired,
  mergeFieldMappingsUpdateSucceeded: PropTypes.bool.isRequired,
  syncEnabled: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
  loyaltyMergeFieldActionRequired: PropTypes.bool.isRequired,
};

export default MergeFieldMappingsSetup;
