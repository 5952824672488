import React from "react";
import { useDispatch } from "react-redux";
import {
  useCreateMailchimpLoyaltyMergeFieldMutation,
  useLoadSessionQuery,
} from "../features/mc-square-api/mc-square-api-slice";
import LoyaltyMergeFieldMappingValidation from "../components/loyalty-merge-field-mapping-validation";
import { setLoyaltyMergeFieldActionRequired } from "../features/mc-square/mc-square-slice";
import { UNKNOWN_USER } from "../constants/proptypes";

const LoyaltyMergeFieldMappingValidationContainer = () => {
  const dispatch = useDispatch();

  const [
    triggerCreateMailchimpLoyaltyMergeField,
    {
      isLoading: creatingMailchimpLoyaltyMergeField,
      isError: errorCreatingMailchimpLoyaltyMergeField,
      isSuccess: creatingMailchimpLoyaltyMergeFieldIsSuccess,
    },
  ] = useCreateMailchimpLoyaltyMergeFieldMutation();

  const { data: { mailchimpList } = UNKNOWN_USER } = useLoadSessionQuery();

  if (!mailchimpList) return null;

  return (
    <LoyaltyMergeFieldMappingValidation
      createMailchimpLoyaltyMergeField={triggerCreateMailchimpLoyaltyMergeField}
      creatingMailchimpLoyaltyMergeField={creatingMailchimpLoyaltyMergeField}
      creatingMailchimpLoyaltyMergeFieldIsSuccess={
        creatingMailchimpLoyaltyMergeFieldIsSuccess
      }
      errorCreatingMailchimpLoyaltyMergeField={
        errorCreatingMailchimpLoyaltyMergeField
      }
      mailchimpList={mailchimpList}
      setLoyaltyMergeFieldActionRequired={(actionRequired) => {
        dispatch(setLoyaltyMergeFieldActionRequired(actionRequired));
      }}
    />
  );
};

LoyaltyMergeFieldMappingValidationContainer.propTypes = {};

export default LoyaltyMergeFieldMappingValidationContainer;
