import React from "react";
import PropTypes from "prop-types";
import CreateMailchimpList from "../components/create-mailchimp-list";
import { useCreateListMutation } from "../features/mc-square-api/mc-square-api-slice";

const CreateMailchimpListContainer = ({
  newListName,
  close,
  onCreatedNewList,
}) => {
  const [triggerCreateList, createListResult] = useCreateListMutation();

  return (
    <CreateMailchimpList
      newListName={newListName}
      onCreatedNewList={onCreatedNewList}
      close={close}
      createList={triggerCreateList}
      createListResult={createListResult}
    />
  );
};

CreateMailchimpListContainer.propTypes = {
  close: PropTypes.func.isRequired,
  newListName: PropTypes.string.isRequired,
  onCreatedNewList: PropTypes.func.isRequired,
};

export default CreateMailchimpListContainer;
