import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import {
  MAILCHIMP_LIST_PROP_TYPES,
  SELECTED_MAILCHIMP_LIST_PROP_TYPES,
} from "../constants/proptypes";
import SpinnerLabel from "./spinner-label";
import MailchimpList from "./mailchimp-list";

const trackAudienceSelection = (selectedList) => {
  if (selectedList) {
    ReactGA.event({
      category: "audience selection",
      action: "select",
      label: "select audience",
    });
  }
};

const AudienceSelectionSetup = ({
  lists,
  fetchingLists,
  canCreateList,
  errorLoadingLists,
  selectedList: initialSelectedList = null,
  saveSelectedList,
  savingSelectedList,
  mergeFieldMappingsEnabled,
}) => {
  const [selectedList, setSelectedList] = useState(initialSelectedList);

  const navigate = useNavigate();

  return (
    <>
      <h1>Choose an audience</h1>
      <p>
        Importing your Square customers to a Mailchimp audience lets you collect
        and organize valuable contact data in one place.
      </p>

      <MailchimpList
        lists={lists}
        fetchingLists={fetchingLists}
        canCreateList={canCreateList}
        selectedList={selectedList}
        errorLoadingLists={errorLoadingLists}
        onSelectedListChange={(selectedList) => {
          setSelectedList(selectedList);
          trackAudienceSelection(selectedList);
        }}
      />

      <form>
        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-primary"
            onClick={async () => {
              await saveSelectedList(selectedList);

              if (mergeFieldMappingsEnabled) {
                navigate("/merge_field_mappings");
              }
            }}
            disabled={!selectedList}
          >
            {savingSelectedList ? (
              <SpinnerLabel label="Continue to map fields" />
            ) : (
              "Continue to map fields"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

AudienceSelectionSetup.propTypes = {
  lists: PropTypes.arrayOf(MAILCHIMP_LIST_PROP_TYPES).isRequired,
  fetchingLists: PropTypes.bool.isRequired,
  canCreateList: PropTypes.bool.isRequired,
  errorLoadingLists: PropTypes.bool.isRequired,
  selectedList: SELECTED_MAILCHIMP_LIST_PROP_TYPES,
  saveSelectedList: PropTypes.func.isRequired,
  savingSelectedList: PropTypes.bool.isRequired,
  mergeFieldMappingsEnabled: PropTypes.bool.isRequired,
};

export default AudienceSelectionSetup;
