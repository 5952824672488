import PropTypes from "prop-types";

export const MAILCHIMP_USER_PROP_TYPES = PropTypes.exact({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  authorized: PropTypes.bool.isRequired,
  remoteListId: PropTypes.string,
  listName: PropTypes.string,
});

// represents a mailchimp list selected within the UI
export const SELECTED_MAILCHIMP_LIST_PROP_TYPES = PropTypes.exact({
  remoteListId: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
  webId: PropTypes.number.isRequired,
});

export const SQUARE_USER_PROP_TYPES = PropTypes.exact({
  id: PropTypes.number,
  name: PropTypes.string,
  enabledSetupSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  setupComplete: PropTypes.bool.isRequired,
  showDashboard: PropTypes.bool.isRequired,
  mailchimpList: SELECTED_MAILCHIMP_LIST_PROP_TYPES,
  mailchimpUser: MAILCHIMP_USER_PROP_TYPES,
  loginCount: PropTypes.number.isRequired,
  lastLoginAt: PropTypes.string,
  justLoggedIn: PropTypes.bool.isRequired,
  justSignedUp: PropTypes.bool.isRequired,
  mismatchedMailchimpUser: PropTypes.bool.isRequired,
  impersonating: PropTypes.bool.isRequired,
});

export const UNKNOWN_USER = {
  id: null,
  enabledSetupSteps: [],
  setupComplete: false,
  showDashboard: false,
  loginCount: 0,
  justLoggedIn: false,
  justSignedUp: false,
  mismatchedMailchimpUser: false,
  impersonating: false,
};

export const MERGE_FIELD_MAPPING = PropTypes.shape({
  squareField: PropTypes.string,
  mailchimpField: PropTypes.string,
  dataType: PropTypes.string,
});

export const MERGE_FIELD_MAPPING_WITH_ERRORS = PropTypes.exact({
  squareField: PropTypes.string,
  squareFieldErrors: PropTypes.string,
  mailchimpField: PropTypes.string,
  mailchimpFieldErrors: PropTypes.string,
  dataType: PropTypes.string,
});

export const SQUARE_MERGE_FIELD = PropTypes.objectOf(
  PropTypes.exact({
    text: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })
);

export const MAILCHIMP_MERGE_FIELD = PropTypes.exact({
  helpText: PropTypes.string,
  name: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

// represents a mailchimp list as retrieved from mailchimp's API
export const MAILCHIMP_LIST_PROP_TYPES = PropTypes.exact({
  id: PropTypes.string.isRequired,
  webId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const MUTATION_RESULT_PROP_TYPES = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  error: PropTypes.shape({}),
  isUninitialized: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
});

export const FLATTENED_SYNC_BATCH_PROP_TYPES = PropTypes.exact({
  status: PropTypes.oneOf([
    "in_progress",
    "succeeded",
    "failed",
    "retrying",
    "canceled",
  ]).isRequired,
  total: PropTypes.number,
  pending: PropTypes.number,
  failures: PropTypes.number,
  task: PropTypes.string.isRequired,
});

export const SYNC_PROP_TYPES = PropTypes.exact({
  id: PropTypes.number.isRequired,
  initial: PropTypes.bool.isRequired,
  problems: PropTypes.exact({
    failed: PropTypes.number.isRequired,
    ignored: PropTypes.number.isRequired,
    retrying: PropTypes.number.isRequired,
    resolved: PropTypes.number.isRequired,
  }).isRequired,
  createdAgo: PropTypes.string.isRequired,
  updatedAgo: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  task: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  flattenedSyncBatchStatuses: PropTypes.arrayOf(
    FLATTENED_SYNC_BATCH_PROP_TYPES
  ),
});

export const SYNCS_PROP_TYPE = PropTypes.arrayOf(SYNC_PROP_TYPES);

export const PAGINATION_TYPE = PropTypes.exact({
  count: PropTypes.number.isRequired,
  items: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  last: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  prev: PropTypes.number,
  next: PropTypes.number,
});

export const SYNC_ERROR_PROP_TYPE = PropTypes.exact({
  id: PropTypes.number.isRequired,
  worker: PropTypes.string.isRequired,
  occurrences: PropTypes.number.isRequired,
  resolved: PropTypes.bool.isRequired,
  ignored: PropTypes.bool.isRequired,
  friendlyMessage: PropTypes.string.isRequired,
  retriesRemaining: PropTypes.number.isRequired,
  status: PropTypes.oneOf(["resolved", "ignored", "retrying", "failed"])
    .isRequired,
  metadata: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  manualRetrySyncBatchId: PropTypes.number,
  manualRetrySyncId: PropTypes.number,
});

export const SYNC_ERRORS_PROP_TYPES = PropTypes.arrayOf(SYNC_ERROR_PROP_TYPE);
