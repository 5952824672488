import React from "react";
import AudienceSelectionSetup from "../components/audience-selection-setup";
import {
  useGetListsQuery,
  useLoadSessionQuery,
  useSaveSelectedListMutation,
} from "../features/mc-square-api/mc-square-api-slice";

// TODO: test me
const AudienceSelectionSetupContainer = () => {
  const [triggerSaveSelectedList, { isLoading: savingSelectedList }] =
    useSaveSelectedListMutation();

  const {
    data: { lists, constraints: { mayCreate: canCreateList } } = {
      lists: [],
      constraints: { mayCreate: false },
    },
    isFetching: fetchingLists,
    isError: errorLoadingLists,
  } = useGetListsQuery();

  const {
    data: { mailchimpList, enabledSetupSteps },
  } = useLoadSessionQuery();

  return (
    <AudienceSelectionSetup
      lists={lists}
      fetchingLists={fetchingLists}
      canCreateList={canCreateList}
      errorLoadingLists={errorLoadingLists}
      saveSelectedList={triggerSaveSelectedList}
      savingSelectedList={savingSelectedList}
      selectedList={mailchimpList}
      mergeFieldMappingsEnabled={enabledSetupSteps.includes(
        "merge_field_mappings"
      )}
    />
  );
};

AudienceSelectionSetupContainer.propTypes = {};

export default AudienceSelectionSetupContainer;
