import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Modal from "./modal";
import remoteErrors from "../services/remote-errors";
import { MUTATION_RESULT_PROP_TYPES } from "../constants/proptypes";
import SpinnerLabel from "./spinner-label";

const CreateMailchimpList = ({
  newListName,
  close,
  onCreatedNewList,
  createList,
  createListResult,
}) => {
  const creatingNewList = createListResult.isLoading;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const fieldHasErrors = (field) =>
    errors[field] || remoteErrors(createListResult, field);

  return (
    <Modal isOpen>
      <h3>Settings for &quot;{newListName}&quot;</h3>

      <form
        onSubmit={handleSubmit(async (values) => {
          const result = await createList({ ...values, newListName });

          if (!result.error) {
            onCreatedNewList({
              remoteListId: result.data.id,
              listName: result.data.name,
              webId: result.data.webId,
            });
            close();
          }
        })}
      >
        <fieldset>
          <div className="wink-form-field-container">
            <div className="wink-form-label-container">
              <label
                className="wink-form-label"
                htmlFor="from-name"
                id="from-name-label"
              >
                From Name
              </label>
              <span
                aria-describedby="from-name-label"
                className="wink-form-label-secondary"
              >
                Required
              </span>
            </div>
            <input
              className={`wink-form-field ${
                fieldHasErrors("fromName") ? "wink-form-field-error" : ""
              }`}
              maxLength="100"
              id="from-name"
              {...register("fromName", {
                required: true,
              })}
            />
            <p
              aria-describedby="from-name-label"
              className="wink-form-description wink-form-description-error"
            >
              {errors.fromName?.type === "required" &&
                "From Name is a required field."}
            </p>
            <p
              aria-describedby="from-name-label"
              className="wink-form-description wink-form-description-error"
            >
              {remoteErrors(createListResult, "fromName")}
            </p>
            <p aria-describedby="from-name-label">
              This is the name your emails will come from. Use something your
              subscribers will instantly recognize, like your company name.
            </p>
          </div>
          <div className="wink-form-field-container">
            <div className="wink-form-label-container">
              <label
                className="wink-form-label"
                htmlFor="permission-reminder"
                id="permission-reminder-label"
              >
                Permission Reminder
              </label>
              <span
                aria-describedby="permission-reminder-label"
                className="wink-form-label-secondary"
              >
                Required
              </span>
            </div>
            <input
              className={`wink-form-field ${
                fieldHasErrors("permissionReminder")
                  ? "wink-form-field-error"
                  : ""
              }`}
              id="permission-reminder"
              {...register("permissionReminder", {
                required: true,
              })}
            />
            <p
              aria-describedby="permission-reminder-label"
              className="wink-form-description wink-form-description-error"
            >
              {errors.permissionReminder?.type === "required" &&
                "Permission Reminder is a required field."}
            </p>

            <p
              aria-describedby="from-name-label"
              className="wink-form-description wink-form-description-error"
            >
              {remoteErrors(createListResult, "permissionReminder")}
            </p>
            <p aria-describedby="permission-reminder-label">
              Remind recipients how they signed up to your audience. This will
              appear in the footer of emails sent to the audience and can be
              changed on the Mailchimp website. For example, &quot;We send
              special offers to customers who purchase from our website.&quot;
            </p>
          </div>
          <div className="wink-form-control-container">
            <label className="wink-form-control-label" id="double-opt-in">
              <input
                type="checkbox"
                {...register("enableDoubleOptin")}
                className="wink-form-control wink-form-control-checkbox"
              />
              Enable double opt-in
            </label>
            <p
              aria-describedby="double-opt-in"
              className="wink-form-description"
            >
              Sent contacts an opt-in confirmation email when they subscribe to
              your audience.
            </p>
          </div>
        </fieldset>
        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-secondary"
            onClick={() => close(false)}
            disabled={creatingNewList}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="wink-button wink-button-primary"
            disabled={creatingNewList}
          >
            {creatingNewList ? (
              <SpinnerLabel label="Create audience" />
            ) : (
              <>Create audience</>
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

CreateMailchimpList.propTypes = {
  close: PropTypes.func.isRequired,
  newListName: PropTypes.string.isRequired,
  createList: PropTypes.func.isRequired,
  onCreatedNewList: PropTypes.func.isRequired,
  createListResult: MUTATION_RESULT_PROP_TYPES.isRequired,
};

export default CreateMailchimpList;
