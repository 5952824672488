import React from "react";
import PropTypes from "prop-types";
import {
  MAILCHIMP_MERGE_FIELD,
  MERGE_FIELD_MAPPING_WITH_ERRORS,
  SQUARE_MERGE_FIELD,
} from "../constants/proptypes";
import DeleteIcon from "./icons/delete-icon";
import SquareMergeFieldSelect from "./square-merge-field-select";
import MailchimpMergeFieldSelect from "./mailchimp-merge-field-select";

const MergeFields = ({
  mergeFieldMappings = [],
  setMergeFieldMappings,
  squareMergeFields,
  mailchimpMergeFields,
  createMailchimpMergeField,
  creatingMailchimpMergeField,
}) => {
  const addMergeFieldMapping = () => {
    setMergeFieldMappings([
      ...mergeFieldMappings,
      { squareField: null, mailchimpField: null },
    ]);
  };

  const selectMergeField = (mergeField, index) => {
    const precedingOptions = mergeFieldMappings.slice(0, index);
    const followingOptions = mergeFieldMappings.slice(index + 1);
    setMergeFieldMappings([
      ...precedingOptions,
      mergeField,
      ...followingOptions,
    ]);
  };

  const removeMergeField = (index) => {
    const precedingOptions = mergeFieldMappings.slice(0, index);
    const followingOptions = mergeFieldMappings.slice(index + 1);
    setMergeFieldMappings([...precedingOptions, ...followingOptions]);
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th width="47%">Square</th>
            <th width="47%">Mailchimp</th>
            <th aria-label="Delete Merge Field" />
          </tr>
        </thead>
        <tbody>
          {mergeFieldMappings.map((mergeFieldMapping, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={`mergeFieldMapping${index}`}>
              <td>
                <SquareMergeFieldSelect
                  squareMergeFields={squareMergeFields}
                  mergeFieldMapping={mergeFieldMapping}
                  onChange={(selectedSquareMergeField) =>
                    selectMergeField(
                      {
                        squareField: selectedSquareMergeField?.value,
                        mailchimpField:
                          mergeFieldMappings[index].mailchimpField,
                        dataType: mergeFieldMappings[index].mailchimpField,
                      },
                      index
                    )
                  }
                  isDisabled={mergeFieldMapping.mailchimpField === "EMAIL"}
                  invalid={!!mergeFieldMapping.squareFieldErrors}
                  index={index}
                />
                <p className="wink-form-description wink-form-description-error">
                  {mergeFieldMapping.squareFieldErrors}
                </p>
              </td>
              <td>
                <MailchimpMergeFieldSelect
                  mailchimpMergeFields={mailchimpMergeFields}
                  mergeFieldMapping={mergeFieldMapping}
                  onChange={(selectedMailchimpMergeField) =>
                    selectMergeField(
                      {
                        squareField: mergeFieldMappings[index].squareField,
                        mailchimpField:
                          selectedMailchimpMergeField?.tag || null,
                        dataType: selectedMailchimpMergeField?.type || null,
                      },
                      index
                    )
                  }
                  createMailchimpMergeField={createMailchimpMergeField}
                  creatingMailchimpMergeField={creatingMailchimpMergeField}
                  isDisabled={mergeFieldMapping.mailchimpField === "EMAIL"}
                  invalid={!!mergeFieldMapping.mailchimpFieldErrors}
                  index={index}
                />
                <p className="wink-form-description wink-form-description-error">
                  {mergeFieldMapping.mailchimpFieldErrors}
                </p>
              </td>
              <td className="merge-table-action-icon">
                {mergeFieldMapping.mailchimpField !== "EMAIL" && (
                  <button
                    type="button"
                    className="link no-underline"
                    onClick={() => removeMergeField(index)}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        type="button"
        className="wink-button wink-button-secondary w-100"
        onClick={addMergeFieldMapping}
      >
        Add another mapping
      </button>
    </div>
  );
};

MergeFields.propTypes = {
  mergeFieldMappings: PropTypes.arrayOf(MERGE_FIELD_MAPPING_WITH_ERRORS),
  setMergeFieldMappings: PropTypes.func.isRequired,
  squareMergeFields: SQUARE_MERGE_FIELD.isRequired,
  mailchimpMergeFields: PropTypes.arrayOf(MAILCHIMP_MERGE_FIELD).isRequired,
  createMailchimpMergeField: PropTypes.func.isRequired,
  creatingMailchimpMergeField: PropTypes.bool.isRequired,
};

export default MergeFields;
