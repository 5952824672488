import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  squareMergeFields: null,
  selectedSyncId: null,
  withinPaymentReadUpdateFlow: false,
  loyaltyMergeFieldActionRequired: false,
};

export const mcSquareSlice = createSlice({
  name: "mcSquare",
  initialState,
  reducers: {
    setSquareMergeFields(draftState, action) {
      draftState.squareMergeFields = action.payload;
    },
    setSelectedSyncId(draftState, action) {
      draftState.selectedSyncId = action.payload;
    },
    setLoyaltyMergeFieldActionRequired(draftState, action) {
      draftState.loyaltyMergeFieldActionRequired = action.payload;
    },
    setWithinPaymentReadUpdateFlow(draftState, action) {
      draftState.withinPaymentReadUpdateFlow = action.payload;
    },
  },
});

export const {
  setSquareMergeFields,
  setSelectedSyncId,
  setLoyaltyMergeFieldActionRequired,
  setWithinPaymentReadUpdateFlow,
} = mcSquareSlice.actions;
export default mcSquareSlice.reducer;
